.aboutUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutUs h3 {
  color: #fff;
  text-align: center;
  font-family: "Krona One";
  font-size: 28px;
}

.about-container{
  background: url("/public/images/BackGrounds/aboutTeam.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.aboutUs-enter {
  animation: slideInFromTop 0.5s ease-out;
}

.aboutUs-enter-active {
  animation-fill-mode: forwards;
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.aboutUs-exit {
  animation: slideOutToTop 0.5s ease-out;
}

.aboutUs-exit-active {
  animation-fill-mode: forwards;
}
