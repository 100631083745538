.footer {
  background-color: #01493F;
  background-image: linear-gradient(0deg, #01493F 0%, #000000 100%);
  text-align: center;
  padding: 5px;
}

.footer-icon{
  padding: 15px 15px 5px 15px;
  width: 20px;
  cursor: pointer;
  height: auto;
  transition: ease .3s all;
}

.footer-icon:hover{
  transform: translateY(3px);
}

.footer-nav{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-nav li{
  padding: 15px 25px 0 0;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
}