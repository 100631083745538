@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&family=Krona+One&family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Afacad";
  src: local("Afacad"),
    url(./fonts/Afacad-VariableFont_wght.ttf) format("truetype");
}

ul li {
  list-style: none;
}

a {
  text-decoration: none !important;
}

.react-tweet-theme * {
  font-family: revert;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Krona One";
  font-style: normal;
  font-weight: 400;
  line-height: 124.925%;
  letter-spacing: -0.56px;
}

.vectorL {
  width: 0;
  height: 0;
  border-left: 144px solid transparent;
  border-right: 144px solid transparent;
  border-top: 32px solid #171717;
  filter: drop-shadow(0px 4px 30px #000);
}

.vectorS1 {
  width: 96px;
  height: 32px;
  border-left: 92px solid transparent;
  border-bottom: 36px solid #171717;
  transform: rotate(180deg);
  filter: drop-shadow(0px 4px 30px #000);
}

.vectorS2 {
  width: 96px;
  height: 32px;
  border-right: 92px solid transparent;
  border-bottom: 36px solid #171717;
  transform: rotate(180deg);
  filter: drop-shadow(0px 4px 30px #000);
}

@media (max-width: 550px) {
  .vectorL {
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-top: 22px solid #171717;
  }

  .vectorS1 {
    width: 66px;
    height: 32px;
  }
  .vectorS2 {
    width: 66px;
    height: 32px;
  }
}
