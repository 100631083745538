.slider {
  position: relative;
  overflow: hidden;
  height: 550px;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
  height: 550px;
}

.slide {
  min-width: 100%;
  overflow: hidden;
  position: relative;
}

.slide h2 {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 34px;
  width: 300px;
  color: #fff;
  transition: transform 1s ease;
  text-align: center;
}

.animate-description {
  animation-delay: 10s;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(350px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide img {
  height: 100%;
  width: 100%;
}

.slide p {
  position: absolute;
  bottom: 35%;
  left: 25%;
  transform: translateX(-50%);
  color: #fff;
  width: 50%;
  text-align: center;
  opacity: 0;
  animation: slideUp 1s forwards;
}

.info-button {
  width: 384px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  background: linear-gradient(
    90deg,
    rgba(1, 1, 1, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  color: #fff;
  border: 2px solid #fff;
  border-radius: 37px;
  cursor: pointer;
  padding: 15px;
}

.scan-button {
  overflow: hidden;
  transition: color 0.5s;
}

.scan-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 5%;
  height: 100%;
  background: rgba(255, 255, 255, 0.323);
  filter: blur(10px);
  transition: left 0.5s;
}

.scan-button:hover::before {
  left: 100%;
}

.btn {
  height: 100%;
}

.btn:hover {
  opacity: 90%;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-btn {
  left: 1px;
  transition: ease 0.3s;
}

.prev-btn:hover {
  background: radial-gradient(
    100% 50% at 0.53% 50%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(153, 153, 153, 0) 100%
  );
}

.next-btn {
  right: 1px;
  transition: ease 0.3s;
}

.next-btn:hover {
  background: radial-gradient(
    100% 50% at 100% 50%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(153, 153, 153, 0) 100%
  );
}

.slider-dots {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #d9d9d9;
}

@media (max-width: 1024px) {
  .slide p {
    bottom: 30%;
    font-size: 17px;
  }
  .info-button {
    bottom: 15%;
    width: 284px;
    height: 34px;
    font-size: 15px;
    padding: 0px;
  }

  .slider-dots {
    bottom: 30px;
  }
  /* .slider,
  .slider-wrapper{
    height: 600px;
  } */
}

@media (max-width: 750px) {
  /* .slider,
  .slider-wrapper{
    height: 500px;
  } */

  .slide h2 {
    font-size: 18px;
  }
  .slide p {
    bottom: 35%;
    font-size: 12px;
  }
  .info-button {
    font-size: 10px;
    height: 24px;
    width: 175px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 550px) {
  .slide h2 {
    font-size: 18px;
    left: 10px;
  }
  .slide p {
    bottom: 30%;
    font-size: 9px;
  }
  .info-button {
    bottom: 15%;
    width: 130px;
    font-size: 8px;
  }

  .btn img {
    height: 35px;
    width: 30px;
  }
}
