.aboutData {
  text-align: center;
  color: #e1e1e1;
  padding-top: 4.5rem;
  position: relative;
}

.getout-bg {
  background-image: url("/public/images/icons/iconStudio2.png");
  background-size: auto;
  background-repeat: no-repeat;
}

.people {
  transition: width 2s;
}

.aboutData h2 {
  color: #c4c4c4;
  margin-bottom: 2rem;
  font-size: 28px;
}

.aboutData-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
}

.aboutData-container img {
  margin: 2rem;
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.aboutData-container p {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  width: 400px;
  font-size: 20px;
  text-align: start;
  color: #c4c4c4;
  padding: 2rem 0rem;
  animation-delay: 2s;
  animation: scale-in-center 3s forwards;
}

.aboutData-container p:last-child {
  text-align: end;
}

.options-container {
  padding: 2rem 0 6rem 0;
  width: 100%;
  text-align: center;
}

.options-container button {
  border-radius: 30px;
  background-color: #333;
  font-size: 32px;
  color: #d9d9d9;
  width: 384px;
  height: 47px;
  margin-top: 2rem;
  cursor: pointer;
  border: none;
  animation: scale-in-center 1s forwards;
  animation-delay: 2s;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.aboutData.show .aboutData::before {
  display: none;
}

.aboutData.show .aboutData-container {
  flex-direction: row;
}

.aboutData.show .aboutData-container img {
  display: block;
  opacity: 1;
}

.aboutData.show .aboutData-container p {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 1280) {
  .aboutData-container p {
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .aboutData::before {
    top: 5%;
    left: -80%;
    width: 400px;
    height: 600px;
  }
  .aboutData-container p,
  .aboutData-container p:last-child {
    font-size: 14px;
  }

  .aboutData.show .aboutData-container p {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .aboutData-container p,
  .aboutData-container p:last-child {
    font-size: 10px;
  }
  .aboutData.show .aboutData-container p {
    width: 155px;
  }
}

@media (max-width: 550px) {
  .aboutData::before {
    left: -30%;
    width: 300px;
    height: 450px;
  }

  .aboutData h2 {
    font-size: 23px;
  }

  .aboutData-container img {
    width: 120px;
    height: 43px;
  }

  .aboutData.show .aboutData-container p {
    width: 100px;
    font-size: 8px;
  }

  .options-container button {
    width: 340px;
    height: 47px;
    font-size: 25px;
  }
}
