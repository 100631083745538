.containerNews {
  position: relative;
  background: radial-gradient(
    50% 50% at 50% 0%,
    rgba(5, 72, 20, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(23, 23, 23, 1);
}

.containerNews::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/BackGrounds/newsPrincipal.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.13;
}

.containerNews > * {
  position: relative;
}

.latestNews {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0px;
}

h2 {
  color: #fff;
  text-align: center;
}

.news-container {
  position: relative;
  padding: 3rem;
  margin: 2rem;
  background-image: url("/public/images/BackGrounds/newsPrincipal.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  background-blend-mode: darken;
  background-color: rgba(33, 31, 31, 0.823);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 1px 16px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 1px 16px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 1px 16px 5px rgba(0, 0, 0, 0.75);
}

.mainNew {
  box-shadow: 3px 1px 16px 5px rgba(0, 0, 0, 0.75);
  width: 720px;
  height: auto;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.mainNew img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainNew-data {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 50px 15px;
}

.mainNew-data h4 {
  font-size: 36px;
  font-weight: 500;
}

.mainNew-data p {
  font-size: 6px;
  letter-spacing: 1px;
  width: auto;
}
.mainNew-data a {
  text-decoration: none;
  color: #fff;
}

.more-news {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.newsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: 1rem;
  margin: 1.5rem;
  grid-template-columns: repeat(2, 0.5fr);
}

.newsItem {
  box-shadow: 3px 1px 16px 5px rgba(0, 0, 0, 0.75);
  width: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.newsItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsList-container span {
  cursor: pointer;
  color: #fff;
  padding-left: 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
}

@media (max-width: 1380px) {
  .newsList {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .newsItem {
    width: 150px;
  }
  .newsItem img {
    padding-left: 0rem;
  }
}

@media (max-width: 1080px) {
  .news-container {
    flex-direction: column;
  }
  .mainNew {
    width: 600px;
    margin-bottom: 1rem;
  }

  .newsList {
    display: flex;
  }

  .newsItem {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .mainNew {
    width: 400px;
  }

  .mainNew-data h4 {
    font-size: 26px;
  }

  .mainNew-data p {
    font-size: 4px;
  }
}
