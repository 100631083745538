.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  font-weight: 400;
  border-bottom: 1px solid #fff;
}

.navBar ul {
  display: flex;
  align-items: center;
}

.navBar ul li {
  list-style: none;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  transition: all .4s;
}

.navBar ul li:hover {
  color: #9f9f9f;
}

.custom-toggler {
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
}

.navBar .img-logo {
  left: 50%;
  transform: translateX(-50%);
  height: 45px;
  cursor: pointer;
}

@media (max-width: 900px) {

  .custom-toggler {
    display: block;
  }

  .navBar ul {
    position: absolute;
    top: 70px;
    left: -1px;
    z-index: 2;
    background-color: #000;
    width: 100%;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius:8px;
    display: none;
  }

  .navBar ul.visible {
    display: flex;
  }

  .navBar ul li{
    width: 100%;
    padding: 15px 20px;
    text-align: start;
    border-bottom: 1px solid #fff;
  }
}
