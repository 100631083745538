.allNews img {
  width: 100%;
}
.react-tweet-theme {
  height: fit-content;
}

.social,
.subscription {
  margin: 4rem 0rem;
  text-align: center;
}

.featured-title {
  display: flex;
  align-items: center;
  margin-left: 6rem;
}

.featured-title img {
  width: 150px;
  height: auto;
}

.title-data {
  margin-left: 3rem;
}

.title-data h3 {
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 1.5rem;
}

.title-data span {
  margin-top: 20px;
  font-size: 14px;
}

.featured-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  align-items: center;
  row-gap: 30px;
  padding: 3rem;
}

.news {
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 350px;
  width: 300px;
}

.news-img {
  width: auto;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: start;
}

.news-img span {
  background-color: #d9d9d9;
  padding: 4px;
  font-size: 12px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 5px;
}

.news-data {
  padding: 15px;
}

.news-data {
  text-align: start;
}

.news-data h4 {
  font-size: 23px;
}

.news-data span {
  font-size: 12px;
}

.allNews-container {
  text-align: center;
}

.allNews-container button,
.subscription-form button {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  padding: 6px 30px;
  font-size: 9px;
}

.social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}

.social-title-data h3{
  font-size: 30px;
}

.social-title-data span{
  font-size: 14px;
}

.social-item {
  width: 500px;
  border: 1px solid #d9d9d9;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.header-item-data {
  display: flex;
  align-items: center;
}

.social-data {
  text-align: start;
}

.social-data h5 {
  font-size: 13px;
  margin-bottom: 0px;
}
.social-data span {
  font-size: 8px;
  color: #b7b3b3;
}

.item-footer {
  text-align: start;
  padding: 15px;
}

.item-footer p {
  font-size: 15px;
  margin-bottom: 0.4rem;
}

.item-footer span {
  background-color: #e3e3e3;
  font-size: 10px;
  padding: 5px;
  border-radius: 3px;
}

.header-item-data img {
  margin: 0px 10px;
  border-radius: 50px;
  background-color: #b7b3b3;
  width: 40px;
  height: 40px;
}

.subscription-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}


.subscription-title img {
  width: 120px;
  height: auto;
  margin-right: 4rem;
}

.subscription-title-data {
  margin-left: 6rem;
  text-align: start;
}
.subscription-title-data h3{
  font-size: 30px;
}
.subscription-form {
  margin: 2.5rem 0rem;
  text-align: center;
}

.subscription-form form {
  display: flex;
  margin: 2rem 4rem;
}

.form-group {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: start;
  flex-wrap: wrap;
}

.form-group label {
  font-size: 13px;
}

.form-group input {
  width: 400px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
}

.form-group span {
  font-size: 13px;
  color: #b7b3b3;
  padding-top: 5px;
}

.feedback {
  text-align: center;
  margin: 6rem 0rem;
}

.feedback-title h3 {
  font-size: 30px;
}

.feedback-title span {
  font-size: 14px;
}

.feedback-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 6rem 0rem;
}

.feedback-item {
  background-color: #ededed;
  border-radius: 6px;
  width: 375px;
  height: 150px;
  margin: 10px;
}

.item-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.item-data img {
  border-radius: 50px;
  background-color: #d5d5d5;
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.feedback-item p {
  text-align: start;
  padding: 15px;
  font-weight: 300;
  font-size: 12px;
}

@media (max-width: 1010px){
  .subscription-title{
    flex-direction: column;
  }

  .subscription-title img{
    margin: 0;
  }
  .subscription-title-data{
    margin-left: 0px;
    padding: 15px;
  }
  .subscription-form form{
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
} 

@media (max-width: 740px) {

  .featured-title {
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    text-align: center;
    margin: 1rem;
  }

  .featured-container{
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .news{
    width: 250px;
  }

  .feedback-item{
    margin: .5rem;
  }
}