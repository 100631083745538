.newsDetails-conatiner {
  position: relative;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(5, 72, 20, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(23, 23, 23, 1);
}

.newsDetails-conatiner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/BackGrounds/newsPrincipal.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.13;
}

.newsDetails-conatiner > * {
  position: relative;
}

.imgNews {
  width: 700px;
  height: auto;
}

.newsDetail-header {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  text-align: start;
}

.header-data {
  width: 35%;
  margin: 0 1rem;
  background-image: url("/public/images/BackGrounds/news2.png");
  object-fit: cover;
  padding: 15px;
  border-radius: 10px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-data h1 {
  color: #000;
  text-align: center;
  font-weight: 600;
}

.header-data span {
  font-size: 15px;
  margin-top: 1rem;
  padding: 10px;
}

.newsDetails-conatiner * {
  color: #fff;
  text-align: justify;
  padding: 1rem 6rem;
  line-height: 1.5;
}

.moreNews-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  margin: 1.5rem;
}

.moreNews {
  text-align: end;
  padding: 10px;
}

.moreNews span {
  margin: 2rem 6rem 0rem 0rem;
  color: #fff;
}

.newsItemDetail {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  cursor: pointer;
  border-radius: 8px;
}

.newsItemDetail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spinner {
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: conic-gradient(#ffffff00 10%, #ffffff);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 860px) {
  .imgNews {
    width: 400px;
  }

  .header-data h1 {
    font-size: 23px;
    text-align: center;
  }

  .header-data span {
    font-size: 13px;
  }

  .newsItemDetail {
    margin: 10px;
    width: 200px;
  }
}

@media (max-width: 600px) {
  .imgNews {
    width: 350px;
  }

  .header-data h1 {
    font-size: 18px;
    text-align: center;
  }

  .header-data span {
    font-size: 10px;
  }

  .newsItemDetail {
    margin: 10px;
    width: 150px;
  }

  .newsDetails-conatiner * {
    font-size: 14px;
    padding: 1rem 3rem;
  }
}
