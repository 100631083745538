

.whatToDo {
  padding: 6rem 2rem;
  margin-top: 2rem;
  color: #fff;
}

.whatToDo h2 {
  font-size: 28px;
}

.whatToDo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 28px;
  flex-wrap: wrap;
  padding: 2rem;
}

.do {
  width: 360px;
  height: 260px;
  border: 5px solid #cbcbcb;
  border-radius: 24px;
  margin: 1rem;

}

.do h4 {

  padding: 2.2rem 2rem;
  font-size: 26px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.do1 .imgA {
  height: 230px;
  width: 230px;
}

.do3 .imgB {
  height: 250px;
  width: 200px;
}

.do1 {
  display: flex;
  background: rgb(26,31,77);
  background: linear-gradient(287deg, rgba(26,31,77,1) 50%, rgba(41,50,179,1) 100%);
}

.do2 {
  background-image: url("/public/images/whatWeDo/Character-Design.jpg");
  background-size: cover;
  border-radius: 18px;
}

.do3 {
  display: flex;
  background: rgb(135,104,97);
  background: linear-gradient(287deg, rgba(135,104,97,1) 50%, rgba(167,104,55,1) 100%);
}

.do4 {
  background: url("/public/images/whatWeDo/iglesia_gotica3-min.gif");
  background-size: cover;
  border-radius: 18px;
}


.do5 {
  background: url("/public/images/whatWeDo/Programming.png");
  background-size: cover;
}

.do6 {
  background: url("/public/images/whatWeDo/gameDesign.png");
  background-size: cover;
  border-radius: 18px;
  overflow: hidden;
}


.do7 {
  background:  url("/public/images/whatWeDo/projectManager.png");
  background-size: cover;
  border-radius: 18px;
  overflow: hidden;
}


.do8 {
  background: url("/public/images/whatWeDo/teamBuilding.png");
  background-size: cover;
  border-radius: 18px;
  overflow: hidden;
}

.do9 {
  background: url("/public/images/whatWeDo/UXUIDesign.png");
  background-size: cover;
  border-radius: 18px;
  overflow: hidden;
}

@media (max-width:860px){
  .do{
    width: 300px;
    height: 220px;
  }
  .do h4{
    font-size: 20px;
    padding: 1rem;
  }
  
    .do1 .imgA {
      height: 200px;
      width: 230px;
    }
    
    .do3 .imgB {
      height: 225px;
      width: 140px;
    }
}


@media (max-width:600px){
  [class^="do"] {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  }
}





